import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./purchase-modal-bundle-tile.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { emptyFunction } from "utils/objectUtils";
import { scrollIntoView } from "utils/scrollUtils";

export const PurchaseModalBundleTile = ({
    bundle,
    className,
    index,
    isLoading,
    selectedBundle,
    setSelectedBundle,
}) => {
    const { banners, includesSandboxxPlus } = bundle;

    function handleSelectBundle(bundle) {
        setSelectedBundle(bundle);
        scrollIntoView("#purchase-modal-bundle-options-purchase-button");
    }

    function renderBanner() {
        const banner = banners[0];
        return <div className={cx(styles.banner)}>{banner?.title}</div>;
    }

    function renderBundle() {
        const lettersText = bundle.credits >= 2 ? "Letters" : "Letter";
        const isSelected = bundle?.title === selectedBundle?.title;
        return (
            <div
                className={cx(styles.purchaseModalBundleTile, className, {
                    [styles["purchaseModalBundleTile--selected"]]: isSelected,
                    [styles["purchaseModalBundleTile--sandboxxPlus"]]:
                        includesSandboxxPlus,
                    [styles["purchaseModalBundleTile--sandboxxPlusSelected"]]:
                        includesSandboxxPlus && isSelected,
                })}
                data-cy={
                    includesSandboxxPlus
                        ? "purchase-modal-bundle-tile-sb+"
                        : `purchase-modal-bundle-tile-${index}`
                }
                onClick={() => {
                    handleSelectBundle(bundle);
                }}
                onKeyDown={(e) =>
                    handleKeyDown(e, () => {
                        handleSelectBundle(bundle);
                    })
                }
                tabIndex="0"
            >
                <div className={styles.left}>
                    {!includesSandboxxPlus && renderBanner()}
                    <div className={styles.letterCountText}>
                        <Text classes={styles.number}>{bundle?.credits}</Text>
                        <Text classes={styles.units}>&nbsp;{lettersText}</Text>
                    </div>
                    {includesSandboxxPlus && bundle?.footerOne && (
                        <Text classes={styles.benefit}>{bundle.footerOne}</Text>
                    )}
                    {includesSandboxxPlus && bundle?.footerTwo && (
                        <Text classes={styles.benefit}>{bundle.footerTwo}</Text>
                    )}
                    {includesSandboxxPlus && bundle?.footerThree && (
                        <Text classes={styles.benefit}>
                            {bundle.footerThree}
                        </Text>
                    )}
                </div>
                {renderBundlePrice(bundle)}
                <div className={styles.gradient} />
            </div>
        );
    }

    function renderBundlePrice(bundle) {
        const { discount, price, total } = bundle;
        const priceToDisplay = discount ? total : price;
        return (
            <div className={styles.price}>
                <span
                    className={cx(styles.current, {
                        [styles["current--hasActivePromotion"]]: price,
                    })}
                >
                    {priceToDisplay}
                </span>
            </div>
        );
    }

    function renderLoading() {
        return (
            <div className={cx(styles.purchaseModalBundleTile)}>
                <div className={styles.left}>
                    <Skeleton height="30px" width="100px" />
                </div>
                <Skeleton height="30px" width="64px" />
            </div>
        );
    }

    return isLoading ? renderLoading() : renderBundle();
};

PurchaseModalBundleTile.defaultProps = {
    bundle: {},
    selectedBundle: {},
    setSelectedBundle: emptyFunction,
};

PurchaseModalBundleTile.propTypes = {
    bundle: PropTypes.object,
    index: PropTypes.number,
    isLoading: PropTypes.bool,
    selectedBundle: PropTypes.object,
    setSelectedBundle: PropTypes.func,
};
