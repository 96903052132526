import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

// Components
import { SmartLink } from "components/SmartLink/SmartLink";
import { Text } from "components/Text/Text";

// CSS
import styles from "./preview-card.module.scss";

// Hooks
import { usePreviewCard } from "./hooks/usePreviewCard";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { emptyFunction } from "utils/objectUtils";

export const PreviewCard = ({ className, isLoading, item, onCardClick }) => {
    /**
     * Custom Hooks
     */

    const { handleCardClick } = usePreviewCard({ item, onCardClick });

    /**
     * End Hooks
     */

    function renderContent() {
        const { imageSrc, text, urls } = item;
        return (
            <div
                className={cx(styles.previewCard, className)}
                onClick={handleCardClick}
                onKeyDown={(e) => handleKeyDown(e, handleCardClick)}
                tabIndex="0"
            >
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${imageSrc})` }}
                />
                <div className={styles.text}>
                    <div className={styles.header}>
                        <Text isBold>{text.header}</Text>
                    </div>
                    <div className={styles.body}>
                        <Text>{text.body}</Text>
                    </div>
                    {text.cta && (
                        <SmartLink
                            className={cx(styles.cta, "link")}
                            config={{ shouldOpenNewWindow: true }}
                            to={urls.cta}
                        >
                            <Text isBold>{text.cta}</Text>
                        </SmartLink>
                    )}
                </div>
            </div>
        );
    }

    function renderLoading() {
        return (
            <div className={cx(styles.previewCard, className)}>
                <div className={styles.image}>
                    <Skeleton
                        height="100%"
                        style={{ transform: "none" }}
                        width="100%"
                    />
                </div>
                <div className={styles.text}>
                    <Skeleton
                        className={styles.header}
                        height="20px"
                        width="70%"
                    />
                    <Skeleton height="16px" width="95%" />
                    <Skeleton
                        className={styles.body}
                        height="16px"
                        width="40%"
                    />
                    <Skeleton height="16px" width="25%" />
                </div>
            </div>
        );
    }

    return isLoading ? renderLoading() : renderContent();
};

PreviewCard.defaultProps = {
    className: "",
    onCardClick: emptyFunction,
};

PreviewCard.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    item: PropTypes.shape({
        imageSrc: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.shape({
            body: PropTypes.string,
            cta: PropTypes.string,
            header: PropTypes.string,
        }),
        urls: PropTypes.shape({
            card: PropTypes.string,
            cta: PropTypes.string,
        }),
    }),
    onCardClick: PropTypes.func,
};
