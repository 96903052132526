import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonRounded } from "components/Buttons/ButtonRounded/ButtonRounded";
import { SignUpPersonaChildLetterWriter } from "./SignUpPersonaChildLetterWriter";
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import styles from "./sign-up-persona-child.module.scss";

// Hooks
import { useSignUpPersonaChild } from "./useSignUpPersonaChild";

export const SignUpPersonaChild = ({
    childPersonas,
    handleGoBackToPersonaSelection,
    handleSelectChildPersona,
    isLoadingChildPersonas,
    selectedRootPersona,
    setCurrentSection,
    setUserInfo,
}) => {
    /**
     * Custom Hooks
     */

    const {
        lastChildPersonasArray,
        isLetterWriterFirstStep,
        shouldDisplayPersonasGrid,
        displayText,
        handleSelectPersona,
    } = useSignUpPersonaChild({
        childPersonas,
        handleSelectChildPersona,
        selectedRootPersona,
        setCurrentSection,
        setUserInfo,
    });

    function renderPersonasGrid() {
        if (isLoadingChildPersonas) {
            return (
                <>
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                </>
            );
        } else {
            return lastChildPersonasArray.map((persona) => {
                return (
                    <ButtonRounded
                        classes={styles.persona}
                        cypressTestId={`sign-up-persona-child-${persona.id}`}
                        key={persona.id}
                        onClick={() => handleSelectPersona(persona)}
                        text={persona.displayName}
                    />
                );
            });
        }
    }

    return (
        <div className={cx(signInStyles.content, styles.signUpPersonaChild)}>
            <div className={signInStyles.top}>
                <div className={signInStyles.header}>
                    <Text classes={signInStyles.text}>
                        {isLoadingChildPersonas ? (
                            <Skeleton
                                variant="rect"
                                width="100%"
                                height="30px"
                            />
                        ) : (
                            displayText.header
                        )}
                    </Text>
                    <Text classes={signInStyles["text-secondary"]}>
                        {isLoadingChildPersonas ? (
                            <Skeleton
                                variant="rect"
                                width="40%"
                                height="10px"
                            />
                        ) : (
                            displayText.subHeader
                        )}
                    </Text>
                </div>
                <div
                    className={cx(styles.personas, {
                        [styles["personas--grid"]]:
                            !isLetterWriterFirstStep &&
                            (isLoadingChildPersonas ||
                                shouldDisplayPersonasGrid),
                    })}
                >
                    {isLetterWriterFirstStep ? (
                        <SignUpPersonaChildLetterWriter
                            handleGoBackToPersonaSelection={
                                handleGoBackToPersonaSelection
                            }
                            handleSelectChildPersona={handleSelectChildPersona}
                            isLoadingChildPersonas={isLoadingChildPersonas}
                            lastChildPersonasArray={lastChildPersonasArray}
                            setUserInfo={setUserInfo}
                            setCurrentSection={setCurrentSection}
                        />
                    ) : (
                        renderPersonasGrid()
                    )}
                </div>
            </div>
        </div>
    );
};

SignUpPersonaChild.propTypes = {
    childPersonas: PropTypes.array,
    handleGoBackToPersonaSelection: PropTypes.func.isRequired,
    handleSelectChildPersona: PropTypes.func.isRequired,
    isLoadingChildPersonas: PropTypes.bool,
    selectedRootPersona: PropTypes.object,
    setCurrentSection: PropTypes.func.isRequired,
    setUserInfo: PropTypes.func.isRequired,
};
