import React from "react";
import PropTypes from "prop-types";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./preview-card-slim.module.scss";

// Hooks
import { usePreviewCardSlim } from "./hooks/usePreviewCardSlim";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const PreviewCardSlim = ({ className, isLoading, message }) => {
    /**
     * Custom Hooks
     */

    const {
        brandMarkUrl,
        buttonStyle,
        cardStyle,
        elements,
        handleClick,
        primaryButton,
        isSponsored,
    } = usePreviewCardSlim({ message });

    /**
     * End Hooks
     */

    if (isLoading) {
        return (
            <div className={`${styles.previewCardSlim} ${className}`}>
                <Skeleton variant="rect" width="100%" height="150px" />
            </div>
        );
    }

    return (
        <div
            className={`${styles.previewCardSlim} ${className}`}
            onClick={handleClick}
            onKeyDown={(e) => handleKeyDown(e, handleClick)}
            role="button"
            style={cardStyle}
            tabIndex={0}
        >
            <div className={styles.content}>
                <Text classes={styles.title}>{elements?.title}</Text>
                <Text classes={styles.body}>{elements?.body}</Text>
                <div className={styles.footer}>
                    {primaryButton && (
                        <button className={styles.button} style={buttonStyle}>
                            {primaryButton.title}
                        </button>
                    )}
                    {isSponsored && (
                        <Text
                            classes={styles.sponsored}
                            style={{ color: cardStyle?.color }}
                        >
                            Sponsored
                        </Text>
                    )}
                </div>
            </div>
            {elements?.mediaUrl && (
                <div className={styles.imageWrapper}>
                    <img
                        alt={elements?.title || "Preview Image"}
                        className={styles.image}
                        src={elements.mediaUrl}
                    />
                    {brandMarkUrl && (
                        <img
                            alt="Brand Mark"
                            className={styles.brandMark}
                            src={brandMarkUrl}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

PreviewCardSlim.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    message: PropTypes.shape({
        metadata: PropTypes.shape({
            messageId: PropTypes.string,
        }),
        elements: PropTypes.shape({
            body: PropTypes.string,
            buttons: PropTypes.arrayOf(
                PropTypes.shape({
                    action: PropTypes.shape({
                        type: PropTypes.string,
                        data: PropTypes.string,
                    }),
                    title: PropTypes.string,
                })
            ),
            defaultAction: PropTypes.shape({
                type: PropTypes.string,
                data: PropTypes.string,
            }),
            mediaUrl: PropTypes.string,
            title: PropTypes.string,
        }),
        payload: PropTypes.shape({
            theme: PropTypes.shape({
                backgroundColor: PropTypes.string,
                buttonBackgroundColor: PropTypes.string,
                buttonTextColor: PropTypes.string,
                textColor: PropTypes.string,
            }),
            images: PropTypes.shape({
                brandMark: PropTypes.string,
            }),
            isSponsored: PropTypes.bool,
        }),
    }),
};

PreviewCardSlim.defaultProps = {
    className: "",
    isLoading: false,
    message: null,
};
