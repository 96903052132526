import { useState, useEffect, useMemo } from "react";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";
import { PersonaEnum } from "enums/PersonaEnum";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { generateDisplayText } from "utils/personaUtils";

export const useSignUpPersonaChild = ({
    childPersonas,
    handleSelectChildPersona,
    selectedRootPersona,
    setCurrentSection,
    setUserInfo,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useMemo
     */

    const lastChildPersonasArray = useMemo(
        () => childPersonas[childPersonas?.length - 1] || [],
        [childPersonas]
    );

    const isLetterWriterFirstStep = useMemo(() => {
        if (selectedRootPersona?.id !== PersonaEnum.LETTER_WRITER) return false;
        if (childPersonas?.length !== 1) return false;
        return (
            lastChildPersonasArray.length > 0 &&
            lastChildPersonasArray.every((persona) => !persona.hasChildren())
        );
    }, [selectedRootPersona, childPersonas, lastChildPersonasArray]);

    const shouldDisplayPersonasGrid =
        !isLetterWriterFirstStep && lastChildPersonasArray?.length > 3;

    /**
     * useState
     */

    const [displayText, setDisplayText] = useState({
        header: "",
        subHeader: "",
    });

    /**
     * useEffect
     */

    useEffect(() => {
        setDisplayText(generateDisplayText(lastChildPersonasArray));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLetterWriterFirstStep, lastChildPersonasArray]);

    /**
     * End Hooks
     */

    function handleSubmit() {
        switch (selectedRootPersona.id) {
            case PersonaEnum.RECRUIT:
                setCurrentSection(NavigationEnum.SIGN_UP_RECRUITING_STATION);
                return;
            default:
                setCurrentSection(NavigationEnum.SIGN_UP_SUCCESS);
                return;
        }
    }

    function handleSelectPersona(persona) {
        if (isLetterWriterFirstStep) return;

        handleSelectChildPersona(persona, { onComplete: handleSubmit });
        setUserInfo((prev) => ({
            ...prev,
            personas: [...prev.personas, persona.id],
        }));
        if (!persona.hasChildren()) {
            if (selectedRootPersona.id === PersonaEnum.RECRUIT) {
                setUserInfo((prev) => ({ ...prev, branchId: persona.id }));
            }
        }
    }

    return {
        displayText,
        handleSelectChildPersona,
        handleSelectPersona,
        isLetterWriterFirstStep,
        lastChildPersonasArray,
        shouldDisplayPersonasGrid,
        setCurrentSection,
        setUserInfo,
        t,
    };
};
