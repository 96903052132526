import React from "react";
import { Skeleton } from "@mui/material";
import PropTypes from "prop-types";

// CSS
import styles from "./wallet-manage-purchase-card-modal-select-design.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";

// Constants
import { NavigationEnum } from "../../constants/constants";
const { SELECT_AMOUNT } = NavigationEnum;

/**
 * TODO: Replace the `letters-compose...` styles as part of WEB-1648
 */
export const WalletManagePurchaseCardModalSelectDesign = ({
    giftCard,
    setGiftCard,
    setCurrentSection,
}) => {
    const { options } = giftCard || {};

    function handleSubmit(option) {
        setGiftCard((prev) => ({ ...prev, selected: option }));
        setCurrentSection(SELECT_AMOUNT);
    }

    function renderOptions() {
        if (!!options.length && options[0].photoUrlFull) {
            return options
                .sort((a, b) => a.priority - b.priority)
                .map((option) => {
                    const { photoUrlFull, priority, provider } = option;
                    const key = `${provider}-${priority}`;
                    const style = { backgroundImage: `url(${photoUrlFull})` };
                    return (
                        <div
                            className="gift-card"
                            data-cy={`gift-card-design-${key}`}
                            key={key}
                            onClick={() => {
                                handleSubmit(option);
                            }}
                            onKeyDown={(e) =>
                                handleKeyDown(e, () => {
                                    handleSubmit(option);
                                })
                            }
                            style={style}
                            tabIndex="0"
                        />
                    );
                });
        } else {
            return (
                <>
                    <Skeleton
                        className={styles.skeleton}
                        height="160px"
                        key={0}
                        variant="rect"
                        width="256px"
                    />
                    <Skeleton
                        className={styles.skeleton}
                        height="160px"
                        key={1}
                        variant="rect"
                        width="256px"
                    />
                    <Skeleton
                        className={styles.skeleton}
                        height="160px"
                        key={2}
                        variant="rect"
                        width="256px"
                    />
                    <Skeleton
                        className={styles.skeleton}
                        height="160px"
                        key={3}
                        variant="rect"
                        width="256px"
                    />
                    <Skeleton
                        className={styles.skeleton}
                        height="160px"
                        key={4}
                        variant="rect"
                        width="256px"
                    />
                    <Skeleton
                        className={styles.skeleton}
                        height="160px"
                        key={5}
                        variant="rect"
                        width="256px"
                    />
                </>
            );
        }
    }

    return (
        <div className="letters-compose-add-ons__select-design">
            <div className="letters-compose-add-ons__select-design__designs">
                {renderOptions()}
            </div>
        </div>
    );
};

WalletManagePurchaseCardModalSelectDesign.propTypes = {
    giftCard: PropTypes.object.isRequired,
    handleClosePurchaseCardModal: PropTypes.func.isRequired,
    setGiftCard: PropTypes.func.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
};
