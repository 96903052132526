import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

// CSS
import styles from "./button-pill.module.scss";

// Utils
import { emptyFunction } from "utils/objectUtils";

export const ButtonPill = (props) => {
    const { className, children, cypressTestId, isActive, isLoading, onClick } =
        props;

    function renderLoading() {
        return <Skeleton variant="rect" width="48px" height="16px" />;
    }

    return (
        <button
            className={cx(styles["button-pill"], className, {
                [styles["button-pill--active"]]: isActive,
            })}
            data-cy={cypressTestId}
            onClick={onClick}
        >
            {isLoading ? renderLoading() : children}
        </button>
    );
};

ButtonPill.defaultProps = {
    className: "",
    onClick: emptyFunction,
};

ButtonPill.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
};
