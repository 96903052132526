import React from "react";
import PropTypes from "prop-types";

// Components
import { PreviewCardSlim } from "components/PreviewCardSlim/PreviewCardSlim";

export const DashboardAdsPreviewCard = ({ isLoading, message }) => {
    return <PreviewCardSlim isLoading={isLoading} message={message} />;
};

DashboardAdsPreviewCard.propTypes = {
    isLoading: PropTypes.bool,
    message: PropTypes.object,
};

DashboardAdsPreviewCard.defaultProps = {
    isLoading: false,
    message: null,
};
