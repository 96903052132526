import { DateTime } from "luxon";

/**
 * Calculates difference between the present and the given time in ISO format
 * @param    {String} iso  An ISO formatted time string
 * @param    {Array} units  An array containing a list of time units to break the duration into
 * @returns   {Object}
 * - @property {Number} units - A key value pair form each unit specified in the units param
 *
 */
export function calculateTimeUntil(iso, units) {
    const today = DateTime.local();
    const comparedDate = DateTime.fromISO(iso);
    return comparedDate.diff(today, units).toObject();
}

export function convertJSDateToObject(date) {
    const newDateObject = DateTime.fromJSDate(date).toObject();
    const dateObject = {
        day: generateDoubleDigitDateString(newDateObject?.day) || "",
        month: generateDoubleDigitDateString(newDateObject?.month) || "",
        year: newDateObject?.year?.toString() || "",
    };
    return dateObject;
}

export function convertISOToObject(date) {
    const newDateObject = DateTime.fromISO(date, { zone: "utc" }).toObject();
    const dateObject = {
        day: generateDoubleDigitDateString(newDateObject?.day) || "",
        month: generateDoubleDigitDateString(newDateObject?.month) || "",
        year: newDateObject?.year?.toString() || "",
    };
    return dateObject;
}

/**
 * This functions takes an ISO date and generates a string formatted
 * according to whatever Luxon DateTime format (e.g., DateTime.DATE_FULL)
 * is provided
 *
 * @param {String} iso
 * @returns {String}
 */
export function convertISOToLocaleString(iso, format = DateTime.DATE_FULL) {
    if (iso) {
        return DateTime.fromISO(iso).toLocaleString(format);
    }
}

/**
 * Calculates the hour, minute, and second difference between the present and the given time
 * @param    {String} timeUntil    An ISO formatted time string
 * @returns  {Object|null}
 * - @property {Number} hours - A number representing the number of hours between the present and the given time
 * - @property {Number} minutes - A number representing the number of minutes between the present and the given time
 * - @property {Number} seconds - A number representing the number of seconds between the present and the given time
 * - @returns {null} if there is an error calculating the time difference
 */
export function findTimeDifference(timeUntil) {
    try {
        return DateTime.local()
            .until(DateTime.fromISO(timeUntil))
            .toDuration(["hours", "minutes", "seconds"])
            .toObject();
    } catch (err) {
        return null;
    }
}

export function findTimeFrom(timeFrom) {
    return DateTime.fromISO(timeFrom, { zone: "utc" })
        .until(DateTime.local())
        .toDuration(["days", "hours", "minutes", "seconds"])
        .toObject();
}

export function generateDoubleDigitDateString(value) {
    if (value < 10) {
        return "0" + value.toString();
    }
    return value.toString();
}

/**
 * Date Dropdown Utilities & Constants
 */

export const DAYS = Array.from({ length: 31 }, (_, i) => ({
    value: String(i + 1).padStart(2, "0"),
    label: String(i + 1),
}));

export const MONTHS = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
];

const currentYear = new Date().getFullYear();
const startYear = currentYear - 100;
export const YEARS = Array.from({ length: 101 }, (_, i) => ({
    value: String(startYear + i),
    label: String(startYear + i),
})).reverse();
