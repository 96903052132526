import React from "react";
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";

// Components
import { Avatar } from "components/Avatar/Avatar";
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { EditAvatarIcon } from "components/Icons/EditAvatarIcon";
import { SandboxxPlusBadge } from "components/Badges/SandboxxPlusBadge/SandboxxPlusBadge";
import { Text } from "components/Text/Text";

// CSS
import "./profile-header.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { formattedPhoneNumber } from "utils/regexUtils";

export const ProfileHeader = ({
    credits,
    handleImageFileSelect,
    handlePurchaseModalToggle,
    lettersSent,
    profileInfo,
    user,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * Constants
     */
    const hasFetchedCredits = credits >= 0;
    const hasFetchedLettersSent = lettersSent?.count >= 0;

    function onClickOpenImageLoad() {
        document.getElementById("image-upload").click();
    }

    function renderAvatar() {
        return (
            user && (
                <div className="profile__header__image">
                    <Avatar isSquare={true} user={user} />
                    <div className="profile__header__image__upload">
                        <input
                            accept="image/*"
                            className="profile__header__image__upload__input"
                            id="image-upload"
                            name="image-upload"
                            type="file"
                            onChange={handleImageFileSelect}
                        />
                        <label
                            className="profile__header__image__upload__input__label"
                            htmlFor="image-upload"
                            onKeyDown={(e) =>
                                handleKeyDown(e, onClickOpenImageLoad)
                            }
                        >
                            <EditAvatarIcon className="edit-icon" />
                        </label>
                    </div>
                </div>
            )
        );
    }

    function renderCredits() {
        return (
            <div className="profile__header__credits">
                <div className="profile__header__credits__number">
                    {hasFetchedCredits ? (
                        credits
                    ) : (
                        <Skeleton height={48} width={68} />
                    )}
                </div>
                <div className="profile__header__credits__text">
                    <Text type="h4">
                        {t("letter_ip_plural", { ns: "common" })}
                    </Text>
                    <Text type="h4">{t("remaining", { ns: "common" })}</Text>
                </div>
            </div>
        );
    }

    function renderLettersSent() {
        return (
            <div className="profile__header__letters-sent">
                <div className="profile__header__letters-sent__number">
                    {hasFetchedLettersSent ? (
                        lettersSent.count
                    ) : (
                        <Skeleton height={48} width={68} />
                    )}
                </div>
                <div className="profile__header__letters-sent__text">
                    <Text type="h4">
                        {t("letter_ip_plural", { ns: "common" })}
                    </Text>
                    <Text type="h4">{t("sent", { ns: "common" })}</Text>
                </div>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className="profile__cta">
                <ButtonPrimary
                    cypressTestId="profile-link-to-letters-compose"
                    onClick={() => history.push("/letters/compose")}
                >
                    <Text>{t("send_letter", { ns: "common" })}</Text>
                </ButtonPrimary>
                <ButtonSecondary
                    cypressTestId="profile-purchase-modal-button"
                    onClick={handlePurchaseModalToggle}
                >
                    <Text>{t("buy_letters", { ns: "common" })}</Text>
                </ButtonSecondary>
            </div>
        );
    }

    return (
        <div className="profile__header">
            <div className="profile__details">
                {renderAvatar()}
                <div className="profile__details__info">
                    <div className="profile__details__info__details">
                        <Text classes="profile__details__info__name" type="h2">
                            {user?.fullName}
                        </Text>
                        <SandboxxPlusBadge className="profile__details__info__sandboxxPlusBadge" />
                        {user.email && (
                            <Text classes="profile__details__info__email">
                                {user.email}
                            </Text>
                        )}
                        {profileInfo.phoneNumber && (
                            <Text
                                classes="profile__details__info__phoneNumber"
                                type="h4"
                            >
                                {formattedPhoneNumber(profileInfo.phoneNumber)}
                            </Text>
                        )}
                    </div>
                    <div className="profile__details__info__stats">
                        {renderCredits()}
                        {renderLettersSent()}
                    </div>
                </div>
            </div>
            {renderButtons()}
        </div>
    );
};
