import { useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

export const useSignUpPersonaChildLetterWriter = ({
    lastChildPersonasArray,
    setUserInfo,
    handleSelectChildPersona,
    setCurrentSection,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [selectedPersonaId, setSelectedPersonaId] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedDay, setSelectedDay] = useState("");
    const [selectedYear, setSelectedYear] = useState("");

    /**
     * End Hooks
     */

    const handlePersonaSelectChange = (event) => {
        setSelectedPersonaId(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleSubmitComplete = () => {
        setCurrentSection(NavigationEnum.SIGN_UP_SUCCESS);
    };

    const handleLetterWriterSubmit = () => {
        if (!selectedPersonaId) return;

        const selectedPersona = lastChildPersonasArray.find(
            (p) => p.id === selectedPersonaId
        );
        if (!selectedPersona) return;

        let birthdayString_MM_DD_YYYY = null;
        if (selectedMonth && selectedDay && selectedYear) {
            birthdayString_MM_DD_YYYY = `${selectedMonth}-${selectedDay}-${selectedYear}`;
            const testDate = new Date(
                `${selectedYear}-${selectedMonth}-${selectedDay}`
            );
            if (isNaN(testDate.getTime())) {
                birthdayString_MM_DD_YYYY = null;
            }
        }

        setUserInfo((prev) => ({
            ...prev,
            personas: [...prev.personas, selectedPersona.id],
            ...(birthdayString_MM_DD_YYYY && {
                birthday: birthdayString_MM_DD_YYYY,
            }),
        }));

        if (birthdayString_MM_DD_YYYY) {
            SandboxxRestAPI.updateUserBirthday({
                birthday: birthdayString_MM_DD_YYYY,
            });
        }

        handleSelectChildPersona(selectedPersona, {
            onComplete: handleSubmitComplete,
        });
    };

    return {
        handleDayChange,
        handleLetterWriterSubmit,
        handleMonthChange,
        handlePersonaSelectChange,
        handleYearChange,
        selectedPersonaId,
        selectedMonth,
        selectedDay,
        selectedYear,
        t,
    };
};
