import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";

// Components
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// Constants
import { months } from "./constants/constants";

// CSS
import "react-datepicker/dist/react-datepicker.css";
import styles from "./date-selector.module.scss";
import "../../css/overrides/reactDatePicker.scss";

// Utils
import { convertJSDateToObject } from "utils/dateTimeUtils";
import { generateEarliestDate, generateYearsRange } from "./utils/utils";

export const DateSelector = (props) => {
    const { currentDate, customInput, onSubmit, popperPlacement, yearsPast } =
        props;

    /**
     * Constants
     */

    const earliestDate = generateEarliestDate(yearsPast);
    const today = new Date();
    const years = generateYearsRange(yearsPast);

    /**
     * useState
     **/

    const [date, setDate] = useState(today);
    const [isOpen, setIsOpen] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        if (currentDate.iso) {
            setDate(new Date(currentDate.iso));
        } else {
            setDate(today);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate]);

    /**
     * End Hooks & Constants
     */

    function handleSubmit(newShipDate) {
        const shipDate = convertJSDateToObject(newShipDate);
        setIsOpen(false);
        const { day, month, year } = shipDate;
        const formattedShipDate = `${year}-${month}-${day}`;
        onSubmit(formattedShipDate);
    }

    return (
        <DatePicker
            customInput={customInput}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
            minDate={earliestDate}
            onChange={(date) => setDate(date)}
            onClickOutside={() => setIsOpen(false)}
            onInputClick={() => setIsOpen(true)}
            open={isOpen}
            popperPlacement={popperPlacement}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                decreaseYear,
                increaseMonth,
                increaseYear,
                prevMonthButtonDisabled,
                prevYearButtonDisabled,
            }) => (
                <div className={styles.dateSelectorHeader}>
                    <button
                        disabled={prevMonthButtonDisabled}
                        onClick={decreaseMonth}
                        className={styles.button}
                    >
                        <ChevronLeftIcon />
                    </button>
                    <select
                        className={styles.selector}
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <button className={styles.button} onClick={increaseMonth}>
                        <ChevronRightIcon />
                    </button>
                    <button
                        disabled={prevYearButtonDisabled}
                        className={styles.button}
                        onClick={decreaseYear}
                    >
                        <ChevronLeftIcon height="16px" width="16px" />
                    </button>
                    <select
                        className={styles.selector}
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <button className={styles.button} onClick={increaseYear}>
                        <ChevronRightIcon height="16px" width="16px" />
                    </button>
                </div>
            )}
            selected={date}
            shouldCloseOnSelect={false}
        >
            <div className={styles.footer}>
                <div className={styles.buttons}>
                    <button
                        className={styles.button}
                        onClick={() => setIsOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className={styles.button}
                        onClick={() => handleSubmit(date)}
                    >
                        OK
                    </button>
                </div>
            </div>
        </DatePicker>
    );
};
