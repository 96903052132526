import React from "react";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { Skeleton } from "@mui/material";

// Components
import { Panel } from "components/Panel/Panel";
import { SearchField } from "components/Inputs/SearchField/SearchField";
import { Text } from "components/Text/Text";
import { UserList } from "components/UserList/UserList";

// CSS
import styles from "./profile-address-book.module.scss";

// Hooks
import { useProfileAddressBook } from "./hooks/useProfileAddressBook";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { preventDefault } from "utils/miscUtils";

// Constants
const chainIconSrc = require("media/icons/chain.svg").default;

export const ProfileAddressBook = withRouter(({ handleGoToContact }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        debouncedSearchTerm,
        handleSearchInputChange,
        handleToggleContactModal,
        isAddressBookEmpty,
        loading,
        results,
    } = useProfileAddressBook();

    /**
     * End Hooks
     */

    function renderNewContact() {
        return (
            <div
                className={cx(styles.item, {
                    [styles["item--empty"]]: isAddressBookEmpty,
                })}
                data-cy="profile-address-book-add-new-contact-button"
                onClick={handleToggleContactModal}
                onKeyDown={(e) => handleKeyDown(e, handleToggleContactModal)}
                tabIndex="0"
            >
                <span className="material-icons">add</span>
                <span>{t("add_new_recipient", { ns: "common" })}</span>
            </div>
        );
    }

    function renderResults() {
        if (loading.contacts) {
            return (
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #e6e6e6",
                            padding: "20px 0",
                        }}
                    >
                        <Skeleton
                            height={40}
                            width={40}
                            variant="circle"
                            style={{ marginRight: "16px" }}
                        />
                        <Skeleton height={16} width={150} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #e6e6e6",
                            padding: "20px 0",
                        }}
                    >
                        <Skeleton
                            height={40}
                            width={40}
                            variant="circle"
                            style={{ marginRight: "16px" }}
                        />
                        <Skeleton height={16} width={150} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #e6e6e6",
                            padding: "20px 0",
                        }}
                    >
                        <Skeleton
                            height={40}
                            width={40}
                            variant="circle"
                            style={{ marginRight: "16px" }}
                        />
                        <Skeleton height={16} width={150} />
                    </div>
                </>
            );
        } else if (!loading.contacts && results && results.length) {
            const icon = {
                shouldShow: (contact) => contact.isSandboxxUser,
                src: chainIconSrc,
            };
            return (
                <UserList
                    classNames={styles.userList}
                    isClickable={true}
                    itemsClassNames={styles.items}
                    handleUserSelect={handleGoToContact}
                    icon={icon}
                    userList={results}
                />
            );
        }
    }

    function renderSearch() {
        return (
            <form className={styles.form} onSubmit={preventDefault}>
                <SearchField
                    cypressTestId="profile-address-book-search-bar"
                    handleSearchInputChange={handleSearchInputChange}
                    placeholder={t("search_for_contact", { ns: "common" })}
                    term={debouncedSearchTerm}
                />
            </form>
        );
    }

    return (
        <Panel classes={styles.profileAddressBook}>
            <div className="panel__header">
                <Text type="h2">{t("address_book", { ns: "common" })}</Text>
            </div>
            {renderSearch()}
            {renderNewContact()}
            <div data-cy="profile-address-book-contact-search-results">
                {renderResults()}
            </div>
        </Panel>
    );
});
