import React, { useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

// Components
import AddIcon from "@mui/icons-material/Add";
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { SearchField } from "components/Inputs/SearchField/SearchField";
import { UserList } from "components/UserList/UserList";

// CSS
import styles from "./support-squad-settings-modal-create-assign.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";

// Constants
import chainIconSrc from "media/icons/chain.svg";
import { Text } from "components/Text/Text";

export const SupportSquadSettingsModalCreateAssign = (props) => {
    const {
        handleContactClick,
        handleCreateAndUpdateSupportSquad,
        handleSearchInputChange,
        isCreatingSquad,
        loadingContacts,
        openContactModalCreate,
        results,
        selectedUserListItem,
        setCurrentSection,
        setHeaderSubText,
        setHeaderText,
        squadRecipientId,
        term,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(t("settings.assign_title", { ns: "support_squad" }));
        setHeaderSubText("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function renderBody() {
        return (
            <div className={styles.body}>
                <SearchField
                    cypressTestId="contact-search-input"
                    handleSearchInputChange={handleSearchInputChange}
                    placeholder={t("settings.assign_search_placeholder", {
                        ns: "support_squad",
                    })}
                    term={term}
                />
                <div
                    className={styles.addNewContact}
                    data-cy="support-squad-assign-new-contact"
                    onClick={openContactModalCreate}
                    onKeyDown={(e) => handleKeyDown(e, openContactModalCreate)}
                    tabIndex="0"
                >
                    <AddIcon />
                    <span>{t("new_contact", { ns: "common" })}</span>
                </div>
                <div className={styles.tabs}>
                    <div className={styles.items}>
                        <span className={cx(styles.tab, styles.active)}>
                            {t("contacts_connections", { ns: "common" })}
                        </span>
                    </div>
                    <div className={styles.highlight}>
                        <div className={styles.bar} />
                    </div>
                </div>
                {renderSearchResults()}
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={cx(styles.buttons)}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="support-squad-assign-next-button"
                    isDisabled={!squadRecipientId || isCreatingSquad}
                    isLoading={isCreatingSquad}
                    onClick={handleCreateAndUpdateSupportSquad}
                >
                    <Text>{t("next", { ns: "common" })}</Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles.button}
                    onClick={() => setCurrentSection("name")}
                >
                    <Text>{t("back", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        );
    }

    function renderSearchResults() {
        const icon = {
            shouldShow: (contact) => contact.isSandboxxUser,
            src: chainIconSrc,
        };
        if (!loadingContacts.contacts && results && results.length) {
            return (
                <div className={styles.userListContainer}>
                    <UserList
                        handleUserSelect={handleContactClick}
                        icon={icon}
                        isClickable={true}
                        selectedUserListItem={selectedUserListItem}
                        userList={results}
                    />
                </div>
            );
        } else if (loadingContacts.contacts) {
            return (
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #e6e6e6",
                            padding: "20px 0",
                        }}
                    >
                        <Skeleton
                            height={40}
                            width={40}
                            variant="circle"
                            style={{ marginRight: "16px" }}
                        />
                        <Skeleton height={16} width={150} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #e6e6e6",
                            padding: "20px 0",
                        }}
                    >
                        <Skeleton
                            height={40}
                            width={40}
                            variant="circle"
                            style={{ marginRight: "16px" }}
                        />
                        <Skeleton height={16} width={150} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #e6e6e6",
                            padding: "20px 0",
                        }}
                    >
                        <Skeleton
                            height={40}
                            width={40}
                            variant="circle"
                            style={{ marginRight: "16px" }}
                        />
                        <Skeleton height={16} width={150} />
                    </div>
                </>
            );
        } else {
            return (
                <div
                    className={cx(
                        styles.userListContainer,
                        styles["userListContainer--empty"]
                    )}
                >
                    <p className={styles.text}>
                        {t("settings.assign_no_contacts", {
                            ns: "support_squad",
                        })}
                    </p>
                </div>
            );
        }
    }

    return (
        <div className={styles.supportSquadSettingsModalCreateAssign}>
            {renderBody()}
            {renderButtons()}
        </div>
    );
};

SupportSquadSettingsModalCreateAssign.propTypes = {
    handleContactClick: PropTypes.func.isRequired,
    handleCreateAndUpdateSupportSquad: PropTypes.func.isRequired,
    handleSearchInputChange: PropTypes.func.isRequired,
    isCreatingSquad: PropTypes.bool.isRequired,
    loadingContacts: PropTypes.object.isRequired,
    openContactModalCreate: PropTypes.func.isRequired,
    results: PropTypes.array.isRequired,
    selectedUserListItem: PropTypes.object.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    squadRecipientId: PropTypes.string.isRequired,
    term: PropTypes.string,
};
