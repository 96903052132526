import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible } from "components/Buttons/Buttons";
import { DateSelector } from "components/DateSelector/DateSelector";
import { MusterOverviewHeaderShipDateButton } from "./components/MusterOverviewHeaderShipDateButton";
import { PencilIcon } from "components/Icons/PencilIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-overview-header.module.scss";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import {
    generateCurrentDateText,
    generateFormattedShipDate,
    generateRecruiterText,
    generateRecruitingStationText,
    generateShipDateText,
    generateShipDateSubText,
} from "./utils";
import { SandboxxRestAPI } from "utils/sandboxx";

// Media
import overviewHeaderGraphicSrc from "media/scenes/muster/muster-overview-header-graphic.png";

export const MusterOverviewHeader = (props) => {
    const {
        branchPersona,
        currentDepLocation,
        currentShipDate,
        daysUntilShip,
        fetchRecruitDashboard,
        handleToggleRecruitsModal,
        isLoadingShipDate,
        recruits,
        rootPersona,
        setShouldShowDepModal,
        showNotification,
    } = props;

    /**
     * useState
     */

    const [formattedShipDate, setFormattedShipDate] = useState({});

    /**
     * useEffect
     */

    useEffect(() => {
        if (currentShipDate?.day) {
            setFormattedShipDate(generateFormattedShipDate(currentShipDate));
        }
    }, [currentShipDate]);

    /**
     * End Hooks & Constants
     */

    async function onSubmit(formattedShipDate) {
        try {
            const { json } = await SandboxxRestAPI.setUserShipDate({
                baseId: "",
                shipDate: formattedShipDate,
            });
            onSetShipDateSuccess(json);
        } catch (err) {
            onSetShipDateFailure(err);
        }
    }

    function onSetShipDateFailure(err) {
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onSetShipDateSuccess(res) {
        fetchRecruitDashboard();
    }

    function renderDaysUntilShip() {
        return isLoadingShipDate ? (
            <Skeleton
                height={24}
                width={36}
                variant="rect"
                style={{ margin: "6px 0px" }}
            />
        ) : (
            <>
                <Text isBold={true} type="h1">
                    {generateShipDateText(daysUntilShip)}
                </Text>
                <Text isBold={true} type="h3">
                    {generateShipDateSubText(
                        currentShipDate,
                        daysUntilShip,
                        formattedShipDate
                    )}
                </Text>
            </>
        );
    }

    return (
        <div
            className={styles.musterOverviewHeader}
            data-cy="muster-overview-ship-date-panel"
        >
            <div className={styles.header}>
                {rootPersona.id === PersonaEnum.RECRUIT && (
                    <>
                        <div
                            className={styles.shipDateInfo}
                            data-cy="muster-overview-ship-date-info-panel"
                        >
                            <Text isBold={true} type="h2">
                                SHIP DATE
                            </Text>
                            {renderDaysUntilShip()}
                        </div>
                        <div>
                            <div>
                                <DateSelector
                                    currentDate={currentShipDate}
                                    customInput={
                                        <MusterOverviewHeaderShipDateButton
                                            classes={styles.editButton}
                                            daysUntilShip={daysUntilShip}
                                        />
                                    }
                                    onSubmit={onSubmit}
                                    popperPlacement="top-end"
                                    yearsPast={5}
                                />
                            </div>
                        </div>
                    </>
                )}

                {rootPersona.id === PersonaEnum.RECRUITER && (
                    <>
                        <div
                            className={styles.recruiterInfo}
                            data-cy="muster-overview-ship-date-info-panel"
                        >
                            <Text isBold={true} type="h2">
                                {generateCurrentDateText()}
                            </Text>
                            <Text
                                classes={styles.recruiterHeadline}
                                isBold={true}
                                type="h1"
                            >
                                {generateRecruiterText(recruits)}
                            </Text>
                        </div>
                        {recruits !== 0 && (
                            <ButtonInvisible
                                classes={styles.seeAllRecruitsButton}
                                cypressTestId="see-all-recruits-button"
                                onClick={handleToggleRecruitsModal}
                                text="See All"
                            />
                        )}
                    </>
                )}
            </div>
            <div className={styles.body}>
                {branchPersona !== "UNKNOWN" && (
                    <Text
                        classes={styles.recruitingStationText}
                        onClick={() => setShouldShowDepModal(true)}
                    >
                        {generateRecruitingStationText(currentDepLocation)}
                        <PencilIcon className={styles.pencilIcon} />
                    </Text>
                )}
                <img
                    alt="Clipart of men doing pushups"
                    className={styles.graphic}
                    src={overviewHeaderGraphicSrc}
                />
            </div>
        </div>
    );
};

MusterOverviewHeader.propTypes = {
    baseId: PropTypes.string,
    currentShipDate: PropTypes.object.isRequired,
    daysUntilShip: PropTypes.number,
    isLoadingShipDate: PropTypes.bool.isRequired,
    updateShipDate: PropTypes.func.isRequired,
};
