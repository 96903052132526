import React from "react";
import cx from "classnames";
import { DateTime } from "luxon";
import { Skeleton } from "@mui/material";

// CSS
import styles from "./inbox-list-item.module.scss";

// Hooks
import { useHover } from "hooks/mouse/useHover";
import { useInboxListItem } from "./hooks/useInboxListItem";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { emptyFunction } from "utils/objectUtils";

// Constants
const trashIconSrc = require("../../../media/icons/trash.svg").default;

export const InboxListItem = (props) => {
    const { isLoading, message, selectedMessage } = props;
    const { imageUrl, isRead, subtitle, timestamp, title } = message || {};

    /**
     * Custom Hooks
     */

    const { t } = useTranslation("inbox");

    const [hoverContainerRef, isContainerHovered] = useHover();
    const [hoverDeleteRef, isDeleteHovered] = useHover();

    const { handleDelete, handleSelect } = useInboxListItem(props);

    /**
     * Constants
     */

    const date = timestamp && DateTime.fromMillis(timestamp).toFormat("MMM yy");
    const isSelected = selectedMessage?.id === message?.id;
    const onSelect = isDeleteHovered ? emptyFunction : handleSelect;

    /**
     * End Constants
     */
    if (isLoading) {
        return (
            <div className={cx(styles.item, styles["item--loading"])}>
                <div className={styles.image}>
                    <Skeleton height={70} width={0} />
                </div>
                <div className={styles.info}>
                    <div className={styles.header}>
                        <Skeleton height={14} width="40%" />
                        <Skeleton height={12} width="15%" />
                    </div>
                    <div className={styles.body}>
                        <Skeleton height={14} width="50%" />
                    </div>
                    <div className={styles.divider} />
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={cx(styles.item, {
                    [styles["item--selected"]]: isSelected,
                })}
                onClick={onSelect}
                onKeyDown={(e) => handleKeyDown(e, onSelect)}
                ref={hoverContainerRef}
                tabIndex="0"
            >
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${imageUrl})` }}
                />

                <div className={styles.info}>
                    <div className={styles.header}>
                        <span className={styles.title}>{title}</span>
                        <span
                            className={cx(styles.date, {
                                [styles["date--visible"]]: !isContainerHovered,
                            })}
                        >
                            {date}
                        </span>
                        <img
                            alt={t("delete_icon", { ns: "common" })}
                            className={cx(styles.delete, {
                                [styles["delete--visible"]]: isContainerHovered,
                            })}
                            onClick={handleDelete}
                            onKeyDown={(e) => handleKeyDown(e, handleDelete)}
                            ref={hoverDeleteRef}
                            src={trashIconSrc}
                            tabIndex="0"
                        />
                    </div>
                    <div className={styles.body}>
                        <span className={styles.preview}>{subtitle}</span>
                        <div
                            className={cx(styles.unread, {
                                [styles["unread--visible"]]: !isRead,
                            })}
                        />
                    </div>
                    <div className={styles.divider} />
                </div>
            </div>
        );
    }
};
