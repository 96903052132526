import React, { useMemo } from "react";
import { Skeleton } from "@mui/material";

// Components
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DogTagIcon } from "components/Icons/DogTagIcon";
import { GenderIcon } from "components/Icons/GenderIcon";
import { MilitaryBaseIcon } from "components/Icons/MilitaryBaseIcon";
import { Panel } from "components/Panel/Panel";
import { ProfileFieldsEdit } from "./components/ProfileFieldsEdit/ProfileFieldsEdit";
import { Text } from "components/Text/Text";

// CSS
import "./profile-fields.scss";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Hooks
import { useProfileFields } from "./hooks/useProfileFields";

// Localization
import { useTranslation } from "localization/localization";
import { getCountryTranslation } from "localization/utils";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { isEmptyObject } from "utils/objectUtils";
import {
    generateBranchPrettyName,
    generateIntentionPrettyName,
    generateMilitaryStatusPrettyName,
    generateRelationshipPrettyName,
} from "utils/userUtils";

export const ProfileFields = ({
    depLocation,
    handleShowPersonaSelectionModal,
    handleShowProfileDepsModal,
    handleShowShipDateModal,
    onProfileUpdateSuccess,
    profileInfo,
    setShouldShowProfileAddressModal,
    setShouldShowProfileDeleteAccountModal,
    togglePhoneVerificationModal,
}) => {
    /**
     * Custom Hooks
     */

    const { i18n, t } = useTranslation();

    const {
        handleOpenPersonaSelectionModal,
        handleChange,
        isEditing,
        toggleFormEditState,
        userStaging,
    } = useProfileFields({
        handleShowPersonaSelectionModal,
        onProfileUpdateSuccess,
        profileInfo,
    });

    /**
     * useMemo
     */

    const userCountryPretty = useMemo(() => {
        return profileInfo?.address?.country
            ? getCountryTranslation(
                  profileInfo.address.country,
                  i18n.resolvedLanguage
              )
            : "";
    }, [i18n.resolvedLanguage, profileInfo]);

    /**
     * Constants
     */

    const isProfileLoaded = isEmptyObject(profileInfo);
    const arePersonasLoaded = !!profileInfo.personas;
    const branchPersona = arePersonasLoaded && profileInfo.personas[2];
    const rootPersona = arePersonasLoaded && profileInfo.personas[0];
    const isLetterWriter = rootPersona?.id === PersonaEnum.LETTER_WRITER;
    const isPreparingForBasic = rootPersona?.id === PersonaEnum.RECRUIT;
    const isBuildingCareer = rootPersona?.id === PersonaEnum.CAREER;
    const isRecruiter = rootPersona?.id === PersonaEnum.RECRUITER;

    /**
     * End Hooks
     */

    function renderProfileFields() {
        return isEditing
            ? renderProfileFieldsEdit()
            : renderProfileFieldsDisplay();
    }

    function renderProfileFieldsDisplay() {
        const linkDisabledClass = isProfileLoaded ? "" : "disabled";
        return (
            <div className="profile__fields__display">
                <div className="profile__fields__header">
                    <div className="profile__fields__header__left">
                        <Text data-cy="profile-fields-h2" type="h2">
                            {t("fields.personal_details", { ns: "profile" })}
                        </Text>
                    </div>
                    <div className="profile__fields__header__right">
                        <span
                            className={`panel__header__link link ${linkDisabledClass}`}
                            data-cy="profile-fields-edit-profile"
                            onClick={toggleFormEditState}
                            onKeyDown={(e) =>
                                handleKeyDown(e, toggleFormEditState)
                            }
                            tabIndex="0"
                        >
                            {t("fields.edit_profile", { ns: "profile" })}
                        </span>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <AccountCircleOutlinedIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>{t("name", { ns: "common" })}</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-address"
                        >
                            {isProfileLoaded ? (
                                <>
                                    <Skeleton height={16} width={150} />
                                </>
                            ) : profileInfo.firstName &&
                              profileInfo.lastName ? (
                                <span>
                                    {profileInfo.firstName}{" "}
                                    {profileInfo.lastName}
                                </span>
                            ) : (
                                <span>
                                    {t("not_specified", { ns: "common" })}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <FmdGoodOutlinedIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>{t("address", { ns: "common" })}</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-address"
                        >
                            {isProfileLoaded ? (
                                <>
                                    <Skeleton height={16} width={150} />
                                    <Skeleton height={16} width={100} />
                                    <Skeleton height={16} width={140} />
                                </>
                            ) : profileInfo.address?.line1 ? (
                                <>
                                    <span>{profileInfo.address?.line1}</span>
                                    <span>{profileInfo.address?.line2}</span>
                                    <span>
                                        {profileInfo.address?.city},{" "}
                                        {profileInfo.address?.state}{" "}
                                        {profileInfo.address?.zipcode}
                                    </span>
                                    {userCountryPretty && (
                                        <span>{userCountryPretty}</span>
                                    )}
                                </>
                            ) : (
                                <span>
                                    {t("not_specified", { ns: "common" })}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <PhoneOutlinedIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>{t("phone", { ns: "common" })}</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-phone"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : (
                                profileInfo.phoneNumber ||
                                t("not_specified", { ns: "common" })
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <PeopleAltOutlinedIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>
                                {t("use_sandboxx_to", { ns: "common" })}
                            </span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-role"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : rootPersona ? (
                                generateIntentionPrettyName(rootPersona.id)
                            ) : (
                                t("not_specified", { ns: "common" })
                            )}
                        </div>
                    </div>
                </div>
                {arePersonasLoaded && isLetterWriter && (
                    <div className="profile__fields__display__item">
                        <div className="profile__fields__display__item__left">
                            <div className="profile__fields__display__item__icon">
                                <PeopleAltOutlinedIcon />
                            </div>
                            <div className="profile__fields__display__item__title">
                                <span>
                                    {t("relationship_to_recruit", {
                                        ns: "common",
                                    })}
                                </span>
                            </div>
                        </div>
                        <div className="profile__fields__display__item__left">
                            <div
                                className="profile__fields__display__item__content"
                                data-cy="profile-fields-branch"
                            >
                                {isProfileLoaded ? (
                                    <Skeleton height={16} width={150} />
                                ) : profileInfo?.personas[1] ? (
                                    generateRelationshipPrettyName(
                                        profileInfo.personas[1].id
                                    )
                                ) : (
                                    t("not_specified", { ns: "common" })
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {arePersonasLoaded &&
                    (isPreparingForBasic ||
                        isBuildingCareer ||
                        isRecruiter) && (
                        <div className="profile__fields__display__item">
                            <div className="profile__fields__display__item__left">
                                <div className="profile__fields__display__item__icon">
                                    <DogTagIcon />
                                </div>
                                <div className="profile__fields__display__item__title">
                                    <span>
                                        {t("military_status", {
                                            ns: "common",
                                        })}
                                    </span>
                                </div>
                            </div>
                            <div className="profile__fields__display__item__left">
                                <div
                                    className="profile__fields__display__item__content"
                                    data-cy="profile-fields-branch"
                                >
                                    {isProfileLoaded ? (
                                        <Skeleton height={16} width={150} />
                                    ) : profileInfo?.personas[1] ? (
                                        generateMilitaryStatusPrettyName(
                                            profileInfo.personas[1].id
                                        )
                                    ) : (
                                        t("not_specified", { ns: "common" })
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                {arePersonasLoaded &&
                    (isPreparingForBasic ||
                        isBuildingCareer ||
                        isRecruiter) && (
                        <div className="profile__fields__display__item">
                            <div className="profile__fields__display__item__left">
                                <div className="profile__fields__display__item__icon">
                                    <BadgeOutlinedIcon />
                                </div>
                                <div className="profile__fields__display__item__title">
                                    <span>
                                        {t("branch_of_service", {
                                            ns: "common",
                                        })}
                                    </span>
                                </div>
                            </div>
                            <div className="profile__fields__display__item__left">
                                <div
                                    className="profile__fields__display__item__content"
                                    data-cy="profile-fields-branch"
                                >
                                    {isProfileLoaded ? (
                                        <Skeleton height={16} width={150} />
                                    ) : branchPersona ? (
                                        generateBranchPrettyName(
                                            branchPersona.id
                                        )
                                    ) : (
                                        t("not_specified", { ns: "common" })
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                {arePersonasLoaded && isPreparingForBasic && branchPersona && (
                    <div className="profile__fields__display__item">
                        <div className="profile__fields__display__item__left">
                            <div className="profile__fields__display__item__icon">
                                <FmdGoodOutlinedIcon />
                            </div>
                            <div className="profile__fields__display__item__title">
                                <span>
                                    {t("recruiting_station_singular", {
                                        ns: "common",
                                    })}
                                </span>
                            </div>
                        </div>
                        <div className="profile__fields__display__item__left">
                            <div
                                className="profile__fields__display__item__content"
                                data-cy="profile-fields-branch"
                            >
                                {depLocation
                                    ? `${depLocation.locale} • ${depLocation.office}`
                                    : t("not_specified", { ns: "common" })}
                            </div>
                        </div>
                    </div>
                )}
                {arePersonasLoaded && isPreparingForBasic && (
                    <div className="profile__fields__display__item">
                        <div className="profile__fields__display__item__left">
                            <div className="profile__fields__display__item__icon">
                                <MilitaryBaseIcon />
                            </div>
                            <div className="profile__fields__display__item__title">
                                <span>{t("ship_date", { ns: "common" })}</span>
                            </div>
                        </div>
                        <div className="profile__fields__display__item__left">
                            <div
                                className="profile__fields__display__item__content"
                                data-cy="profile-fields-branch"
                            >
                                {isProfileLoaded ? (
                                    <Skeleton height={16} width={150} />
                                ) : profileInfo?.basicTrainingShipDate ? (
                                    profileInfo.basicTrainingShipDate
                                ) : (
                                    t("not_specified", { ns: "common" })
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <GenderIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>{t("gender", { ns: "common" })}</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-gender"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : (
                                profileInfo.gender ||
                                t("not_specified", { ns: "common" })
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <CakeOutlinedIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>{t("date_of_birth", { ns: "common" })}</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-birthday"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : (
                                profileInfo.birthday ||
                                t("not_specified", { ns: "common" })
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <FavoriteBorderOutlinedIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>{t("marital_status", { ns: "common" })}</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-marital-status"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : (
                                profileInfo.maritalStatus ||
                                t("not_specified", { ns: "common" })
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item profile__fields__display__item--delete">
                    <button
                        className="profile__fields__display__item__left profile__fields__display__item__left--action"
                        data-cy="profile-fields-delete-account"
                        onClick={() => {
                            setShouldShowProfileDeleteAccountModal(true);
                            AnalyticsLogger.logDeleteAccountProfileButtonClick();
                        }}
                    >
                        <div className="profile__fields__display__item__icon profile__fields__display__item__icon--delete">
                            <DeleteOutlineIcon />
                        </div>
                        <div className="profile__fields__display__item__title profile__fields__display__item__title--delete">
                            <span>
                                {t("delete_account.delete_account", {
                                    ns: "profile",
                                })}
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    function renderProfileFieldsEdit() {
        return (
            <ProfileFieldsEdit
                branchPersona={branchPersona}
                depLocation={depLocation}
                handleChange={handleChange}
                handleOpenPersonaSelectionModal={
                    handleOpenPersonaSelectionModal
                }
                handleShowProfileDepsModal={handleShowProfileDepsModal}
                handleShowShipDateModal={handleShowShipDateModal}
                isBuildingCareer={isBuildingCareer}
                isLetterWriter={isLetterWriter}
                isPreparingForBasic={isPreparingForBasic}
                isProfileLoaded={isProfileLoaded}
                isRecruiter={isRecruiter}
                profileInfo={profileInfo}
                rootPersona={rootPersona}
                setShouldShowProfileAddressModal={
                    setShouldShowProfileAddressModal
                }
                setShouldShowProfileDeleteAccountModal={
                    setShouldShowProfileDeleteAccountModal
                }
                toggleFormEditState={toggleFormEditState}
                togglePhoneVerificationModal={togglePhoneVerificationModal}
                userCountryPretty={userCountryPretty}
                userStaging={userStaging}
            />
        );
    }

    return <Panel classes="profile__fields">{renderProfileFields()}</Panel>;
};
