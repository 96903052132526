import React from "react";
import { Skeleton } from "@mui/material";

// Components
import { Modal } from "components/Modals/Modal";
import { SearchField } from "components/Inputs/SearchField/SearchField";
import { Text } from "components/Text/Text";
import { UserList } from "components/UserList/UserList";

// CSS
import styles from "./muster-recruits-list-modal.module.scss";

// Hooks
import { useMusterRecruitsListModal } from "./useMusterRecruitsListModal";

// Utils
import { preventDefault } from "utils/miscUtils";

export const MusterRecruitsListModal = ({
    contactList,
    handleToggleRecruitsModal,
    handleToggleRecruitDetailModal,
    isLoadingContactList,
    setSelectedRecruit,
    shipDates,
    shouldShow,
}) => {
    /**
     * Custom Hooks
     */

    const {
        clearSearch,
        debouncedSearchTerm,
        group1To7,
        group8To30,
        group61Plus,
        groupNoShipDate,
        groupShipped,
        handleSearchInputChange,
        isSearching,
        results,
    } = useMusterRecruitsListModal({ contactList });

    /**
     * End Hooks & Constants
     */

    function closeMusterRecruitsListModal() {
        handleToggleRecruitsModal();
        clearSearch();
    }

    function handleSelectContact(recruit) {
        setSelectedRecruit(recruit);
        closeMusterRecruitsListModal();
        handleToggleRecruitDetailModal();
    }

    function renderAllRecruits() {
        return (
            <div className={styles.recruits}>
                {group1To7.length !== 0 && (
                    <div className={styles.group}>
                        <Text classes={styles.label}>
                            SHIPPING IN {shipDates[0]?.label}
                        </Text>
                        <UserList
                            avatarClassNames={styles.avatar}
                            itemsClassNames={styles.userList}
                            shouldDisplayShipDate={true}
                            isClickable={true}
                            handleUserSelect={handleSelectContact}
                            userList={group1To7}
                        />
                    </div>
                )}
                {group8To30.length !== 0 && (
                    <div className={styles.group}>
                        <Text classes={styles.label}>
                            SHIPPING IN {shipDates[1]?.label}
                        </Text>
                        <UserList
                            avatarClassNames={styles.avatar}
                            itemsClassNames={styles.userList}
                            shouldDisplayShipDate={true}
                            isClickable={true}
                            handleUserSelect={handleSelectContact}
                            userList={group8To30}
                        />
                    </div>
                )}
                {group61Plus.length !== 0 && (
                    <div className={styles.group}>
                        <Text classes={styles.label}>
                            SHIPPING IN {shipDates[2]?.label}
                        </Text>
                        <UserList
                            avatarClassNames={styles.avatar}
                            itemsClassNames={styles.userList}
                            shouldDisplayShipDate={true}
                            isClickable={true}
                            handleUserSelect={handleSelectContact}
                            userList={group61Plus}
                        />
                    </div>
                )}
                {groupNoShipDate.length !== 0 && (
                    <div className={styles.group}>
                        <Text classes={styles.label}>
                            NO SHIP DATE SELECTED
                        </Text>
                        <UserList
                            avatarClassNames={styles.avatar}
                            itemsClassNames={styles.userList}
                            shouldDisplayShipDate={true}
                            isClickable={true}
                            handleUserSelect={handleSelectContact}
                            userList={groupNoShipDate}
                        />
                    </div>
                )}
                {groupShipped.length !== 0 && (
                    <div className={styles.group}>
                        <Text classes={styles.label}>SHIPPED</Text>
                        <UserList
                            avatarClassNames={styles.avatar}
                            itemsClassNames={styles.userList}
                            shouldDisplayShipDate={true}
                            isClickable={true}
                            handleUserSelect={handleSelectContact}
                            userList={groupShipped}
                        />
                    </div>
                )}
            </div>
        );
    }

    function renderLoading() {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e6e6e6",
                        padding: "20px 20px",
                        paddingLeft: "0px",
                        paddingTop: "0px",
                    }}
                >
                    <Skeleton
                        height={40}
                        width={40}
                        variant="circle"
                        style={{ marginRight: "16px" }}
                    />
                    <Skeleton height={16} width={150} />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e6e6e6",
                        padding: "20px 20px",
                        paddingLeft: "0px",
                    }}
                >
                    <Skeleton
                        height={40}
                        width={40}
                        variant="circle"
                        style={{ marginRight: "16px" }}
                    />
                    <Skeleton height={16} width={150} />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e6e6e6",
                        padding: "20px 20px",
                        paddingLeft: "0px",
                    }}
                >
                    <Skeleton
                        height={40}
                        width={40}
                        variant="circle"
                        style={{ marginRight: "16px" }}
                    />
                    <Skeleton height={16} width={150} />
                </div>
            </>
        );
    }

    function renderSearchResults() {
        if (results?.length) {
            return (
                <UserList
                    avatarClassNames={styles.avatar}
                    itemsClassNames={styles.userList}
                    shouldDisplayShipDate={true}
                    isClickable={true}
                    handleUserSelect={handleSelectContact}
                    userList={results}
                />
            );
        } else if (!results || !results?.length) {
            return (
                <div className={styles.emptySearch}>
                    <span className={styles.header}>No Results Found</span>
                    <span className={styles.text}>
                        Please check your spelling or try another name
                    </span>
                </div>
            );
        }
    }

    function renderSearchField() {
        return (
            <div className={styles.search}>
                <form className={styles.userSearch} onSubmit={preventDefault}>
                    <SearchField
                        cypressTestId="muster-recruits-search-bar"
                        handleSearchInputChange={handleSearchInputChange}
                        placeholder="Search for a recruit..."
                        term={debouncedSearchTerm}
                    />
                </form>
            </div>
        );
    }

    return (
        <Modal
            bodyClasses={styles.modalBody}
            centered={true}
            className={styles.musterRecruitsModal}
            onHide={closeMusterRecruitsListModal}
            show={shouldShow}
        >
            <Text classes={styles.headline} type="h1">
                Recruits
            </Text>
            {renderSearchField()}
            <div className={styles.container}>
                {isLoadingContactList && renderLoading()}
                {isSearching && !isLoadingContactList && renderSearchResults()}
                {!isSearching && !isLoadingContactList && renderAllRecruits()}
            </div>
        </Modal>
    );
};
