import { useMemo } from "react";

// Platforms
import { Iterable } from "platforms/iterable/iterable";

export const usePreviewCardSlim = ({ message }) => {
    /**
     * useMemo
     */

    const { elements, payload } = message || {};
    const { theme } = payload || {};
    const brandMarkUrl = payload?.images?.brandMark;
    const isSponsored = payload?.isSponsored;
    const buttonStyle = useMemo(
        () => ({
            backgroundColor: theme?.buttonBackgroundColor,
            color: theme?.buttonTextColor,
        }),
        [theme]
    );
    const cardStyle = useMemo(
        () => ({
            backgroundColor: theme?.backgroundColor,
            color: theme?.textColor,
        }),
        [theme]
    );
    const defaultAction = useMemo(() => elements?.defaultAction, [elements]);
    const primaryButton = useMemo(() => elements?.buttons?.[0], [elements]);

    /**
     * End Hooks
     */

    const handleClick = () => {
        if (message) {
            Iterable.trackEmbeddedMessageClickEvent({ message });
        }

        if (
            primaryButton?.action?.data &&
            primaryButton?.action?.type === "openUrl"
        ) {
            window.open(primaryButton.action.data, "_blank");
            return;
        }
        if (defaultAction?.data && defaultAction?.type === "openUrl") {
            window.open(defaultAction.data, "_blank");
            return;
        }
    };

    return {
        buttonStyle,
        cardStyle,
        elements,
        handleClick,
        primaryButton,
        brandMarkUrl,
        isSponsored,
    };
};
