import React from "react";
import PropTypes from "prop-types";

// Components
import { Select } from "components/Inputs/Select/Select";
import { Text } from "components/Text/Text";
import { ButtonPrimary, ButtonInvisible } from "components/Buttons/Buttons";

// Hooks
import { useSignUpPersonaChildLetterWriter } from "./useSignUpPersonaChildLetterWriter";

// CSS
import styles from "./sign-up-persona-child.module.scss";

// Utils
import { MONTHS, DAYS, YEARS } from "utils/dateTimeUtils";

export const SignUpPersonaChildLetterWriter = ({
    handleGoBackToPersonaSelection,
    handleSelectChildPersona,
    isLoadingChildPersonas,
    lastChildPersonasArray,
    setCurrentSection,
    setUserInfo,
}) => {
    const {
        handlePersonaSelectChange,
        handleMonthChange,
        handleDayChange,
        handleYearChange,
        handleLetterWriterSubmit,
        selectedPersonaId,
        selectedMonth,
        selectedDay,
        selectedYear,
        t,
    } = useSignUpPersonaChildLetterWriter({
        lastChildPersonasArray,
        setUserInfo,
        handleSelectChildPersona,
        setCurrentSection,
    });

    return (
        <>
            <div className={styles.input}>
                <Text classes={styles.label} isBold>
                    {t("sign_up_persona_child.relationship_label", {
                        ns: "onboarding",
                    })}
                </Text>
                <Select
                    className={styles.personaSelect}
                    cypressTestId="sign-up-persona-child-select"
                    onChange={handlePersonaSelectChange}
                    required
                    value={selectedPersonaId}
                >
                    <option value="" disabled>
                        {t("select.select_an_option", { ns: "components" })}
                    </option>
                    {lastChildPersonasArray.map((persona) => (
                        <option
                            data-cy={`sign-up-persona-child-option-${persona.id}`}
                            key={persona.id}
                            value={persona.id}
                        >
                            {persona.displayName}
                        </option>
                    ))}
                </Select>
            </div>
            <div className={styles.input}>
                <Text classes={styles.label} isBold>
                    {t("sign_up_persona_child.birthday_label", {
                        ns: "onboarding",
                    })}{" "}
                    ({t("optional", { ns: "common" })})
                </Text>
                <div className={styles.selects}>
                    <Select
                        className={styles.birthdaySelect}
                        cypressTestId="sign-up-birthday-month"
                        onChange={handleMonthChange}
                        value={selectedMonth}
                    >
                        <option value="" disabled>
                            {t("month", { ns: "date_time" })}
                        </option>
                        {MONTHS.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                    <Select
                        className={styles.birthdaySelect}
                        cypressTestId="sign-up-birthday-day"
                        onChange={handleDayChange}
                        value={selectedDay}
                    >
                        <option value="" disabled>
                            {t("day", { ns: "date_time" })}
                        </option>
                        {DAYS.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                    <Select
                        className={styles.birthdaySelect}
                        cypressTestId="sign-up-birthday-year"
                        onChange={handleYearChange}
                        value={selectedYear}
                    >
                        <option value="" disabled>
                            {t("year", { ns: "date_time" })}
                        </option>
                        {YEARS.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                </div>
                <Text classes={styles.disclaimer}>
                    {t("sign_up_persona_child.birthday_disclaimer", {
                        ns: "onboarding",
                    })}
                </Text>
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    cypressTestId="sign-up-persona-child-submit"
                    classes={styles.submitButton}
                    isDisabled={!selectedPersonaId || isLoadingChildPersonas}
                    onClick={handleLetterWriterSubmit}
                    text={t("continue", { ns: "common" })}
                />
                <ButtonInvisible
                    classes={styles.backButton}
                    isDisabled={isLoadingChildPersonas}
                    onClick={handleGoBackToPersonaSelection}
                >
                    <Text>{t("back", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        </>
    );
};

SignUpPersonaChildLetterWriter.propTypes = {
    handleGoBackToPersonaSelection: PropTypes.func.isRequired,
    handleSelectChildPersona: PropTypes.func.isRequired,
    isLoadingChildPersonas: PropTypes.bool,
    lastChildPersonasArray: PropTypes.array,
    setCurrentSection: PropTypes.func.isRequired,
    setUserInfo: PropTypes.func.isRequired,
};
