import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

// Components
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";
import { UserList } from "components/UserList/UserList";

// CSS
import styles from "./referrals-list.module.scss";

// Hooks
import { useReferralsList } from "./hooks/useReferralsList";

// Localization
import { useTranslation } from "localization/localization";

export const ReferralsList = ({
    headerText,
    isLoadingRewardsStatus,
    letterCount,
    list,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { formattedList, isEmptyList } = useReferralsList({ list });

    /**
     * End Hooks
     */

    function renderEmpty() {
        return (
            <div className={styles.empty}>
                <Text type="body">
                    {t("list.empty", {
                        ns: "referrals",
                        type: headerText.toLowerCase(),
                    })}
                </Text>
            </div>
        );
    }

    function renderHeader() {
        const hasLetterCount =
            letterCount !== null && letterCount !== undefined;
        return (
            <div className={styles.header}>
                <Text classes={styles.text} type="h2">
                    {headerText}
                </Text>
                {hasLetterCount && (
                    <Text classes={styles.count} type="body">
                        {t("letter_ip", {
                            count: letterCount,
                            ns: "common",
                        })}
                    </Text>
                )}
            </div>
        );
    }

    function renderList() {
        if (isLoadingRewardsStatus) return renderLoading();
        if (isEmptyList) return renderEmpty();
        if (formattedList)
            return (
                <UserList
                    avatarClassNames={styles.avatar}
                    classNames={styles.userList}
                    isClickable={false}
                    userList={formattedList}
                />
            );
    }

    function renderLoading() {
        return (
            <>
                <div className={styles.loading}>
                    <Skeleton className={styles.circle} variant="circle" />
                    <Skeleton className={styles.rectangle} />
                </div>
                <div className={styles.loading}>
                    <Skeleton className={styles.circle} variant="circle" />
                    <Skeleton className={styles.rectangle} />
                </div>
                <div className={styles.loading}>
                    <Skeleton className={styles.circle} variant="circle" />
                    <Skeleton className={styles.rectangle} />
                </div>
            </>
        );
    }

    return (
        <Panel classes={styles.referralsList}>
            {renderHeader()}
            {renderList()}
        </Panel>
    );
};

ReferralsList.propTypes = {
    headerText: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    letterCount: PropTypes.number,
    list: PropTypes.array,
    referralType: PropTypes.string.isRequired,
};
